<template lang="pug">
  .booking-confirmation-table
    table
      thead
        tr
          th.status.sortable(@click="handleSorting('status')")
            span {{ $t("company_system.booking_confirmation.table.status") }}
            FaIcon.icon(:icon="sortingIcon('status')")
          th.reservation_number.sortable(@click="handleSorting('reservation_code')")
            span {{ $t("company_system.booking_confirmation.table.reservation_number") }}
            FaIcon.icon(:icon="sortingIcon('reservation_code')")
          th.reserver.sortable(@click="handleSorting('reservation_person_name')")
            span {{ $t("company_system.booking_confirmation.table.reserver") }}
            FaIcon.icon(:icon="sortingIcon('reservation_person_name')")
          th.user.sortable(@click="handleSorting('driver_name')")
            span {{ $t("company_system.booking_confirmation.table.user") }}
            FaIcon.icon(:icon="sortingIcon('driver_name')")
          th.application_date.sortable(@click="handleSorting('reserved_at')")
            span {{ $t("company_system.booking_confirmation.table.application_date") }}
            FaIcon.icon(:icon="sortingIcon('reserved_at')")
          th.loan_date.sortable(@click="handleSorting('pick_up_at')")
            span {{ $t("company_system.booking_confirmation.table.loan_date") }}
            FaIcon.icon(:icon="sortingIcon('pick_up_at')")
          th.return_date.sortable(@click="handleSorting('drop_off_at')")
            span {{ $t("company_system.booking_confirmation.table.return_date") }}
            FaIcon.icon(:icon="sortingIcon('drop_off_at')")
          th.rental_store.sortable(@click="handleSorting('companies_pick_up_shop_id')")
            span {{ $t("company_system.booking_confirmation.table.rental_store") }}
            FaIcon.icon(:icon="sortingIcon('companies_pick_up_shop_id')")
          th.sales_channel.sortable(@click="handleSorting('sales_channel')")
            span {{ $t("company_system.booking_confirmation.table.sales_channel") }}
            FaIcon.icon(:icon="sortingIcon('sales_channel')")
      tbody
        template(v-for="item in items")
          tr(
            :key="item.id"
            @click="$emit('select-booking', item)"
          )
            td {{ statuses[item.status] }}
            td {{ item.reservation_code }}
            td {{ item.reservation_person_name }}
            td {{ item.driver_name }}
            td {{ item.reserved_at }}
            td {{ item.pick_up_at }}
            td {{ item.drop_off_at }}
            td {{ item.companies_pick_up_shop.name }}
            td {{ item.sales_channel }}
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    mixins: [withSorting],

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },
      statuses: {
        type: Object,
        default: () => {}
      },

      sortingData: Object
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .booking-confirmation-table
    +listing-container(112px)
    margin-top: 30px
    overflow-x: initial
    overflow-y: auto

    table
      +custom-table
      +matchings-table-header-sticky


      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          &.status,
          &.reservation_number
            width: 9%
          &.reserver,
          &.user,
          &.application_date,
          &.loan_date,
          &.return_date,
          &.rental_store,
          &.sales_channel
            width: 11%

      tbody
        tr
          cursor: pointer
</style>
